/*
https: //codepen.io/stewpedaso/pen/mRbNRY
https: //stackoverflow.com/questions/6542212/use-css3-transitions-with-gradient-backgrounds
https: //www.joshwcomeau.com/gradient-generator/ 
https: //non-boring-gradients.netlify.app/
*/
:root {
  --bg-gradient-1: linear-gradient(0deg,
      rgba(27, 17, 77, 1) -8.35%,
      rgba(29, 22, 87, 1) -5.1%,
      rgba(51, 65, 174, 1) 28.53%,
      rgba(59, 82, 208, 1) 43.72%,
      rgba(53, 100, 213, 1) 51.31%,
      rgba(0, 0, 0, 1) 100.13%);
  --bg-gradient-2: linear-gradient(0deg,
      rgba(84, 0, 84, 1) -8.35%,
      rgba(98, 9, 92, 1) -5.1%,
      rgba(111, 17, 100, 1) 28.53%,
      rgba(138, 33, 116, 1) 43.72%,
      rgba(202, 98, 166, 1) 51.31%,
      rgba(0, 0, 0, 1) 100.13%);
  --bg-gradient-3: linear-gradient(0deg,
        hsl(240deg 100% 20%) -8.35%,
        hsl(279deg 100% 27%) -5.1%,
        hsl(318deg 100% 33%) 28.53%,
        hsl(357deg 100% 40%) 43.72%,
        hsl(36deg 100% 47%) 51.31%,
        rgba(0, 0, 0, 1) 100.13%);
  --bg-gradient-4: linear-gradient(0deg,
      rgb(96.471% 43.137% 100%) -8.35%,
      rgb(93.603% 46.667% 99.461%) -5.1%,
      rgb(90.735% 50.196% 98.922%) 28.53%,
      rgb(87.868% 53.725% 98.382%) 43.72%,
      rgb(85% 57.255% 97.843%) 51.31%,
      rgba(0, 0, 0, 1) 100.13%);
  --bg-gradient-6: linear-gradient(0deg,
      #A7EDFA -8.35%,
      #9AEAF4 -5.1%,
      #8CE6EE 28.53%,
      #BED6E7 43.72%,
      #D7CEE3 51.31%,
      #F0C5DF 67.59%,
      #DC99BA 89.28%,
      #C86D95 100.13%);
  --bg-gradient-5: linear-gradient(0deg,
      #E602BC -8.35%,
      #AE20C5 -5.1%,
      #753ECD 28.53%,
      #2060C6 43.72%,
      #036BC3 51.31%,
      #023D6F 67.59%,
      #011F38 89.28%,
      #000000 100.13%);
  --canvas-bg: var(--bg-gradient-1);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(0deg,
          rgba(27, 17, 77, 1) -8.35%,
          rgba(29, 22, 87, 1) -5.1%,
          rgba(51, 65, 174, 1) 28.53%,
          rgba(59, 82, 208, 1) 43.72%,
          rgba(53, 100, 213, 1) 51.31%,
          rgba(0, 0, 0, 1) 100.13%);
    background: var(--canvas-bg);
}

#play-button {
  position: fixed;
  top: 0;
  right: 0;
  width: 120px;
  height: 120px;
  z-index: 2;
  cursor: pointer;
  background: transparent;
  border: none;
  cursor: pointer;
}

.play-icon {
  fill: white;
  width: 120px;
  height: 120px;
}

.play-icon__play {
  opacity: 1;
}

.play-icon__pause {
  opacity: 0;
}

.play-icon--playing .play-icon__play {
  opacity: 0;
}

.play-icon--playing .play-icon__pause {
  opacity: 1;
}

.loading {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg,
      rgba(27, 17, 77, 1) -8.35%,
      rgba(29, 22, 87, 1) -5.1%,
      rgba(51, 65, 174, 1) 28.53%,
      rgba(59, 82, 208, 1) 43.72%,
      rgba(53, 100, 213, 1) 51.31%,
      rgba(37, 146, 225, 1) 67.59%,
      rgba(12, 219, 245, 1) 89.28%,
      rgba(0, 255, 255, 1) 100.13%);
  transform: rotate(-180deg);
  transition: opacity 3s;
  opacity: 1;
}

.loading--complete {
  opacity: 0;
}


.loading svg {
  display: block;
  margin: auto;
  width: 10rem;
  height: 10rem;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  shape-rendering: auto;
}

.loading path {
  fill: none;
  stroke: #ff0cb8;
  transform: scale(0.8);
  transform-origin: 50px 50px;
}